import {useMediaQuery} from 'react-responsive';
import React, {FC, memo, useCallback, useReducer, useState} from 'react';
import {Modal as CustomModal} from '../../modal';
import Button from '../../button';
import {packagesNames, packagesNamesWithIntervals} from '../../../helpers';
import Payment from '../payment';
import {login} from '../../../requests';
import pricingIcon from '../../../img/payments/pricing-hint.png';
import SignInToContinue from '../../sign-in';
import { twoMontsFree } from '../../../img/svg-icons';
import './styles.scss';

interface ICard {
  id?: string;
  limit?: number;
  paypal_id?: string;
  color?: string;
  stack?: boolean;
  count?: 1;
  price?: number;
  ltdPrice?: number;
  deductableAmount?: number;
  default_card?: boolean;
  popular?: boolean;
  expariationDate?: boolean;
  pendingPayment?: boolean;
  user?: boolean;
  verified?: boolean;
  annual?: boolean;
  ltdUser?: boolean;
  disabled?: boolean;
  name?: string;
  validUser?: boolean;
  refetchUser?: any;
  realPriceAnnual?: number | null;
  realPriceMonth?: number | null;
}

const selCountValue = {
  3: 1,
  7: 2,
  11: 3,
  15: 4,
  19: 5,
};

const ProductCard: FC<ICard> = ({
  id,
  paypal_id,
  color,
  stack = false,
  popular = false,
  count = 3,
  price,
  ltdPrice,
  limit,
  deductableAmount = 0,
  default_card,
  expariationDate,
  pendingPayment,
  user,
  verified,
  annual,
  ltdUser,
  disabled,
  name,
  validUser,
  refetchUser,
  realPriceMonth,
  realPriceAnnual,
}) => {
  const [contactModalOpen, setContactModalOpen] = useReducer((val: boolean) => !val, false);
  const [showPaypalMessage, setShowPaypalMessage] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [signIn, setSignIn] = useState(false);

  // const handelChange = useCallback((val: any) => {
  //   setSelectedCount(val.value);
  // }, []);

  const isMobile = useMediaQuery({maxWidth: 1250});

  const handleClick = useCallback(async () => {
    const url = await login('en');
    if (url) window.location.href = url;
  }, [user, login]);

  return price ? (
    <>
      {default_card ? (
        <div className="product-card default">
          {/* <div className={`product-card-header d-flex ${expariationDate ? '' : ''}`}>
            {!isMobile && (
              <p className="mt-auto">
                All our paid plans are covered by our 30-day refund policy, so if Hexofy is not the right fit for your
                business, we provide a full refund.
              </p>
            )}
          </div> */}
          <div className="body"></div>
        </div>
      ) : (
        <div className={`d-flex flex-wrap product-card position-relative`} key={color}>
          {ltdPrice && (
            <div className="pricing-trigger">
              <div className="pricing-trigger-wrapper"> LIFETIME DEAL ${ltdPrice}</div>
              <div id="triangle-down"></div>
            </div>
          )}
          <div className={`product-card-header ${expariationDate ? '' : 'sm'} w-100`}>
            {realPriceMonth ? <div style={{position: 'absolute', top: '15px', left: '52%', transform: 'translate(-50%, 0%)'}}>{twoMontsFree}</div> : ''}
            <div className="d-flex justify-content-center mt-2">
              <div className="title">{packagesNames[name]}</div>
            </div>
            <div></div>
            <div className="price d-flex justify-content-center">
              {/* <div
                style={{
                  fontSize: realPriceMonth ? '28px' : '48px',
                  fontWeight: 600,
                  color: '#fff',
                  marginRight: realPriceMonth ? '10px' : '',
                }}
              >
                <sup style={{fontSize: realPriceMonth ? '14px' : '26px'}}>$</sup>
                <span className={ltdPrice || realPriceMonth ? 'line-through' : ''}>
                  {realPriceMonth
                    ? realPriceMonth
                    : (price / (annual ? (ltdPrice ? 10 : 12) : 1)).toFixed(
                        annual && name === 'Team' && !ltdPrice ? 1 : 0,
                      )}
                </span>
                {realPriceMonth ? null : <span style={{fontSize: '20px'}}>/mo</span>}
              </div> */}
              {true ? (
                <div style={{fontSize: '48px', fontWeight: 600, color: '#fff'}}>
                  {realPriceMonth ? (
                    <span>
                      <span style={{display: 'inline-block', verticalAlign: 'top', lineHeight: '21px'}}>
                        <sup style={{fontSize: '14px'}}>$</sup>
                        <span className="line-through" style={{fontSize: '28px', marginRight: '10px', fontWeight: 500}}>
                          18
                        </span>
                      </span>
                      <sup style={{fontSize: '26px'}}>$</sup>15
                    </span>
                  ) : (
                    <>
                      <sup style={{fontSize: '26px'}}>$</sup>
                      <span>{price}</span>
                    </>
                  )}
                  <span style={{fontSize: '20px'}}>/mo</span>
                </div>
              ) : null}
            </div>
          </div>
          <div className="w-100">
            {expariationDate && (
              <div title={pendingPayment ? 'Your payment is being processed.' : ''} className="text-center w-100">
                <Button
                  onClick={() => {
                    if (user) {
                      setIsOpenModal(true);
                    } else {
                      setSignIn(true);
                    }
                  }}
                  disabled={!expariationDate || pendingPayment || disabled}
                  className="mt-auto ml-2"
                  outline
                >
                  {ltdPrice ? 'Buy Now' : 'Subscribe'}
                </Button>
              </div>
            )}
            <div
              style={{
                color: '#8793ab',
                fontSize: '14px',
                lineHeight: '20px',
                textAlign: 'center',
                fontWeight: 400,
                padding: '15px 15px 0 15px',
              }}
            >
              {/* {ltdPrice || realPriceAnnual ? (
                <span className="line-through">${realPriceAnnual ? realPriceAnnual : price}</span>
              ) : null}{' '} */}
              <span style={{fontWeight: 600}}>${(ltdPrice ? ltdPrice : price) - deductableAmount}</span>{' '}
              {realPriceAnnual ? 'billed upon purchase' : 'billed upon purchase'}
            </div>
          </div>
          <div className="pricing-card-body">
            {
              <div className="pricing-card-body-item">
                {/* <div className="left-col primary-text">{limit}</div> */}
                <div className="right-col">
                  <strong>{limit}</strong> hrs/{realPriceAnnual ? 'year' : 'month'} of Voice Generation
                </div>
              </div>
            }
            {
              <div className="pricing-card-body-item">
                <div className="right-col">
                  <strong>50+</strong> languages and variants
                </div>
              </div>
            }
            {
              <div className="pricing-card-body-item">
                <div className="right-col">Unlimited Downloads</div>
              </div>
            }
          </div>
        </div>
      )}

      {isOpenModal && (
        <CustomModal
          modalToggle={() => setIsOpenModal(false)}
          isOpen={isOpenModal}
          noCloseIcon
          className={`payment-modal ${showPaypalMessage ? 'no-background' : ''}`}
          modalFooter={null}
          dark
        >
          <Payment
            id={id}
            paypal_id={paypal_id}
            count={count}
            setIsOpenModal={setIsOpenModal}
            showPaypalMessage={showPaypalMessage}
            setShowPaypalMessage={(val: boolean) => setShowPaypalMessage(val)}
            // ltd={!!ltdPrice}
            price={(ltdPrice ? ltdPrice : price) - deductableAmount}
            name={packagesNamesWithIntervals[name]}
            refetchUser={refetchUser}
            annualDiscount={!!realPriceAnnual}
          />
        </CustomModal>
      )}

      {contactModalOpen && (
        <CustomModal
          modalToggle={setContactModalOpen}
          noCloseIcon
          className="contact-support"
          isOpen={contactModalOpen}
          dark
        >
          <div className="text-center all-disabled">
            <img src={pricingIcon} alt="icon" loading="eager" />
            <div>Need to upgrade your plan? </div>
            <div className="support">
              Please contact{' '}
              <a
                href="mailto:billing@hexact.io?subject=Text Reader"
                className="clickable-text"
                aria-label={'mail'}
                target="_blank"
                rel="noopener"
              >
                billing@hexact.io
              </a>
              {'.'}
            </div>
          </div>
        </CustomModal>
      )}
      {signIn && <SignInToContinue handleClose={() => setSignIn(false)} />}
    </>
  ) : null;
};

export default memo(ProductCard);
